
import { defineComponent, reactive, toRefs, ref, onMounted, computed } from 'vue'
import detailLayout from '@/components/detailLayout/index.vue'
import {
  CompetingGoods,
  ReqCompetingGoods,
  ResCompetingGoods,
} from '@/model/competitiveAnalysis/competingGoodsData/competingGoods'
import { FillClass } from '@/model/competitiveAnalysis/fillInformation/fillInformation'
import { useRoute } from 'vue-router'
import productApi from '@/axios/api/product'
import InfoApi from '@/axios/api/info'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import FormItem from '@/components/formItem/index.vue'
import editButton from '@/components/editButton/index.vue'
import { FormItemListType } from '@/types/comm'
// import { ReqCompetitorAccount } from '@/model/competitiveAnalysis/peerData/competitorAccount'
export default defineComponent({
  components: {
    detailLayout,
    FormItem,
    editButton,
  },
  setup() {
    //获取表单ref
    const goodsFormRef = ref()
    //获取路由
    const route = useRoute()
    const router = useRouter()
    //组件数据
    const pageData = reactive({
      goodsForm: new CompetingGoods(),
      goodsFormList: [] as CompetingGoods[],
      formItemList: [
        {
          type: 'subInput',
          prop: 'productCode',
          placeholder: '请输入物料编码',
          label: '物料编码',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'productModel',
          placeholder: '请输入规格型号',
          label: '规格型号',
          disabled: true,
        },

        {
          type: 'subInput',
          prop: 'productName',
          placeholder: '请输入物料名称',
          label: '物料名称',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'deptName',
          placeholder: '请输入所属部门',
          label: '所属部门',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'productManager',
          placeholder: '请输入产品经理',
          label: '产品经理',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'productCategory',
          placeholder: '请输入产品类别',
          label: '产品类别',
          disabled: true,
        },
        {
          type: 'slot',
          prop: 'productImgUrl',
          label: '产品图片',
          disabled: true,
        },
      ] as FormItemListType<FillClass>,
      oldFormList: [] as CompetingGoods[],
      rules: {
        competitorModel: [{ required: true, message: '请输入竞品规格', trigger: 'blur' }],
        competitorModelName: [{ required: true, message: '请输入竞品名称', trigger: 'blur' }],
        innerCode: [{ required: true, message: '请输入竞品编码', trigger: 'blur' }],
        enableStatus: [{ required: true }],
        imageUrl: [{ required: true, message: '请输入竞品地址', trigger: 'blur' }],
      },
      activeId: '-1',
      imgUrl: '',
      productData: {
        productImgUrl: '',
      } as FillClass,
      reqForm: new ReqCompetingGoods(),
      isShow: true,
      resetValue: {} as CompetingGoods,
    })
    //判断是否是详情页面  隐藏按钮
    if (route.name === 'DetailFill') {
      pageData.isShow = false
    }

    //选择事件
    const selectPeer = (val: CompetingGoods) => {
      pageData.activeId = val.accompanyId as string
      pageData.goodsForm = val
      pageData.resetValue = JSON.parse(JSON.stringify(val))
    }
    const getListThen = (res: ResCompetingGoods, type: string) => {
      if (!res.data) return
      res.data = res.data.map((item) => {
        item.productModel = pageData.productData.productModel as string
        item.modelCode = pageData.productData.productCode as string
        item.productName = pageData.productData.productName
        // item.productUrl = 'www.baidu.com' //测试用
        if (route.name === 'addFill') {
          item.enableStatus = 1
        }
        return item
      })
      if (type === 'search') {
        if (res.data?.length === 0) {
          message.info('没有找到')
        } else {
          pageData.goodsFormList = res.data as CompetingGoods[]
          selectPeer(pageData.goodsFormList[0])
        }
      } else {
        res.data?.forEach((item) => {
          pageData.goodsFormList.push(item)
        })
        if (pageData.activeId === '-1') {
          selectPeer(pageData.goodsFormList[0])
        }
      }
      pageData.oldFormList = JSON.parse(JSON.stringify(pageData.goodsFormList))
      pageData.reqForm.currentPage = res.currentPage
      pageData.reqForm.pageSize = res.pageSize
      pageData.reqForm.dataCount = res.dataCount
      console.log(pageData.goodsFormList)
    }
    const getDetailList = (type: string) => {
      if (route.name === 'addFill') {
        InfoApi.NotReportedList(pageData.reqForm)
          .then((res) => {
            getListThen(res, type)
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        InfoApi.DetailList(pageData.reqForm)
          .then((res) => {
            getListThen(res, type)
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }
    //判断是新增还说修改
    if (route.params.id) {
      productApi
        .GetProductByCode({ productCode: route.params.id })
        .then((res) => {
          pageData.productData = res
          console.log(pageData.productData)

          pageData.reqForm.data.productModel = res.productModel as string
          getDetailList('decline')
        })
        .catch((err) => {
          message.error(err)
        })
    }
    // 基础表单校验 - 不为空-数值校验
    const checkeNull = (val: CompetingGoods): Promise<Message> => {
      return new Promise((resolve, reject) => {
        if (!val.competitorModel) {
          reject({
            message: `${val.accompanyName}的竞品规格为空`,
          })
        } else if (!val.innerCode) {
          reject({
            message: `${val.accompanyName}的竞品编码为空`,
          })
        } else if (!val.competitorModelName) {
          reject({
            message: `${val.accompanyName}的竞品名称为空`,
          })
        } else if (val.enableStatus === undefined) {
          reject({
            message: `${val.accompanyName}的开启状态为空`,
          })
        } else if (!val.imageUrl) {
          reject({
            message: `${val.accompanyName}的图片地址为空`,
          })
        } else {
          resolve({
            message: `效验成功`,
          })
        }
      })
    }
    //搜索事件
    const onSearch = (val: string) => {
      pageData.reqForm.data.accompanyName = val
      pageData.reqForm.currentPage = 1
      pageData.goodsFormList.length = 0
      getDetailList('search')
      let ele = document.querySelector('.selectLoadMore') as HTMLElement
      ele.scrollTop = 0
    }

    //重置
    const reset = () => {
      goodsFormRef.value.resetFields()
      pageData.goodsForm.enableStatus = 1
      if (pageData.resetValue.accompanyId) {
        Object.assign(pageData.goodsForm, pageData.resetValue)
      }
    }
    //提交
    const onSubmit = () => {
      goodsFormRef.value.validate().then(() => {
        InfoApi[route.name === 'addFill' ? 'AddProduct' : 'UpdateProduct'](pageData.goodsForm)
          .then((res) => {
            let index = pageData.goodsFormList.findIndex(
              (item) => item.accompanyId === res.accompanyId
            )
            if (route.name === 'addFill') {
              pageData.goodsFormList.splice(index, 1)
              if (pageData.goodsFormList.length === 0) {
                router.push('/competitiveAnalysis/fillInformation')
              } else {
                selectPeer(pageData.goodsFormList[0])
              }
              pageData.oldFormList = JSON.parse(JSON.stringify(pageData.goodsFormList))
            }
            message.success('成功')
          })
          .catch((err) => {
            message.error(err)
          })
      })
    }
    interface Message {
      message: string
    }
    const onSubmitAll = () => {
      let checkeNullList: Promise<Message>[] = []
      let submitForm: CompetingGoods[] = []
      pageData.goodsFormList.forEach((item, index) => {
        console.log(JSON.stringify(item), JSON.stringify(pageData.oldFormList[index]))
        if (JSON.stringify(item) !== JSON.stringify(pageData.oldFormList[index])) {
          checkeNullList.push(checkeNull(item))
          submitForm.push(item)
        }
      })
      if (submitForm.length === 0) {
        message.info('未修改任何数据')
        return
      }
      Promise.all(checkeNullList)
        .then(() => {
          InfoApi[route.name === 'addFill' ? 'BatchAddProduct' : 'BatchUpdateProduct'](submitForm)
            .then((res) => {
              if (res.failure) {
                message.info(`成功${res.success.length}条，${res.failure.join(',')}`)
                if (res.success.length !== 0) {
                  let deleteIdList = res.success.map((item) => {
                    return item.accompanyId
                  })
                  pageData.goodsFormList.forEach((item, index) => {
                    if (deleteIdList.indexOf(item.accompanyId) !== -1) {
                      pageData.goodsFormList.splice(index, 1)
                    }
                  })
                  selectPeer(pageData.goodsFormList[0])
                  pageData.oldFormList = JSON.parse(JSON.stringify(pageData.goodsFormList))
                }
              } else {
                message.success('成功')
                router.push('/competitiveAnalysis/fillInformation')
              }
            })
            .catch((err) => {
              message.error(err)
            })
        })
        .catch((err) => {
          message.error(err.message)
        })
    }
    //图片地址改变事件
    const onImgBlur = () => {
      pageData.imgUrl = pageData.goodsForm.imageUrl as string
    }
    //添加下拉继续加载事件
    onMounted(() => {
      const SELECTDOWN_DOM = document.querySelector('.selectLoadMore') as HTMLElement
      console.log(SELECTDOWN_DOM)
      SELECTDOWN_DOM?.addEventListener('scroll', function () {
        const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
        if (CONDITION) {
          if (pageData.reqForm.currentPage) {
            pageData.reqForm.currentPage += 1
          }
          if (pageData.reqForm.dataCount) {
            if (pageData.goodsFormList.length < pageData.reqForm.dataCount) {
              getDetailList('decline')
            }
          }
        }
      })
    })
    const routName = computed(() => {
      return route.name
    })
    return {
      onSearch,
      ...toRefs(pageData),
      selectPeer,
      reset,
      onSubmit,
      onSubmitAll,
      goodsFormRef,
      onImgBlur,
      fallbackImg: require('@/assets/img/fallbackImg.svg'),
      routName,
    }
  },
})
